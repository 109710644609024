import { Center, Flex, TextInput, Button, Group, Box, Space, Text, NumberInput, PasswordInput } from "@mantine/core";
import { isNotEmpty, useForm } from '@mantine/form';
import CENTER_STYLE from "../core/CenterStyle";
import { useEffect, useState } from "react";
import { retrieveTokenFromLocalStorage, useAuth } from "../core/auth/AuthenticationPrivuder";
import { useNavigate } from "react-router-dom";
import LogoVertical from "./LogoVerical";


export function AuthForm() {
  const n = useNavigate();

  useEffect(() => {
    if (retrieveTokenFromLocalStorage() != null) {
      n("/");
    }
  }, []);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      phone_number: '',
      password: '',
    },

    validate: {
      phone_number: (value) => value.length < 1 ? "هذا الحقل مطلوب" : null,
      password: (value) => value.length >= 1 ? null : "عليك ادخال كلمة السر",
    },
  });


  const [loading, setLoading] = useState(false);

  // todo: add feedback loop with alerts of server error
  const auth = useAuth();



  return (
    <form onSubmit={form.onSubmit(async (values) => {
      setLoading(true);

      const s = values.phone_number.toString()
        ;

      values.phone_number = s.startsWith('+') ? s : '+964' + s;
      console.log(values);
      const res = await handleFormSubmit(values, auth);

      if (res == false) {
        alert("حدث خطآ تحقق من معلوماتك و اتصالك بالانترنت")
      }

      setLoading(false);
    })}>
      <div style={CENTER_STYLE}>
        <Flex
          mih={50}
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"

        >
          <Space h={'lg'}></Space>
          <LogoVertical />
          <Space h={'sm'}></Space>



          <h2 style={
            { margin: 12 }
          }>لوحة التحكم</h2>
          <Text size="sm">أهلا و سهلا بكم! يرجى تسجيل الدخول</Text>

          <Space h={'lg'}></Space>




          <Group grow>
            <Flex direction={'column'} w={332} gap={'sm'}>
              <NumberInput
                hideControls
                style={
                  {
                    direction: 'ltr'
                  }
                }

                leftSection={
                  <div style={{ paddingLeft: 20 }}>
                    <Text size="xs">+964</Text>
                  </div>
                }
                size="md"
                placeholder="رقم الهاتف"
                key={form.key('phone_number')}
                {...form.getInputProps('phone_number')}
              />
              <PasswordInput
                size="md"
                key={form.key('password')}
                {...form.getInputProps('password')}

                placeholder="كلمة السر"
              />
              <Button type="submit" disabled={loading}>تسجيل الدخول</Button>

            </Flex>
          </Group>

          <Space h={'xl'}></Space>
          <Space h={'xl'}></Space>
          <Space h={'xl'}></Space>

        </Flex>
      </div>
    </form>
  );
}


async function handleFormSubmit(data: any, auth: any): Promise<boolean> {

  try {
    console.log(data)
    const response = await auth.login(
      data
    );



    return true;

    // navigate("/form-response", { state: { data: data, status: true } })
  }

  catch (error) {
    // navigate("/form-response", { state: { status: false } })
    return false;
  }
}