import { ActionIcon, Box, Flex, Group, ScrollArea, Text, } from "@mantine/core";
import { IconArrowLeft, IconArrowRight, IconImageInPicture, IconSignLeft, IconSignRight } from "@tabler/icons-react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import ReactImagePickerEditor, { ImagePickerConf } from "react-image-picker-editor";

interface ImagesStatsProps {
    amount: number,
    title: string,
    children?: any,
    // images: Array<string> | null

}

export const config2: ImagePickerConf = {
    borderRadius: '8px',
    language: 'en',
    width: '380px',
    height: '200px',
    objectFit: 'contain',
    compressInitial: null,
    hideAddBtn: true,
    hideEditBtn: true,
};

const ImagesStats: FunctionComponent<ImagesStatsProps> = ({ amount, title, children, }) => {

    const viewport = useRef<HTMLDivElement>(null);





    const scrollToCenter = () =>
        viewport.current!.scrollTo({ left: viewport.current!.scrollLeft - 125, behavior: 'smooth', });

    const scrollToCenter2 = () =>
        viewport.current!.scrollTo({ left: viewport.current!.scrollLeft + 125, behavior: 'smooth', });


    return (
        <Flex direction={'column'} gap={'sm'}>
            <Flex direction={'row'} justify={'space-between'}>
                <Text size="lg">{title}</Text>


                <Flex gap={'sm'}>
                    <ActionIcon variant="default" onClick={scrollToCenter2} >
                        <IconArrowRight></IconArrowRight>
                    </ActionIcon>
                    <ActionIcon variant="default" onClick={scrollToCenter} >
                        <IconArrowLeft></IconArrowLeft>
                    </ActionIcon>

                </Flex>
            </Flex>

            <ScrollArea type="hover" offsetScrollbars scrollbars='x' viewportRef={viewport} >


                <Flex direction={'row'} gap={'md'}>

                    {children}


                    {/* 
                    {[...(selected === null || selected as any === "null" || selected === undefined ? [] : selected), '']?.map(e => {
                        let f = false;
                        return < ReactImagePickerEditor
                            key={e}
                            config={config2}
                            imageSrcProp={e}
                            imageChanged={(newDataUri: any) => {
                                console.log(newDataUri);
                                if (newDataUri == "" || newDataUri == null || e == newDataUri) return;
                                let did = false;

                                for (let c = 0; c < (selected?.length ?? 0); c++) {

                                    if (selected![c] == e) {
                                        selected![c] = newDataUri;
                                        did = true;
                                        setS(selected);
                                        break;
                                    }


                                }

                                if (!did) {
                                    const r = selected?.push(newDataUri) == undefined;
                                    if (r) setS(selected ?? [newDataUri]);


                                }

                                setS(selected);
                            }} />
                    })} */}


                </Flex>


            </ScrollArea>

            <Flex direction={'row'} gap={'lg'}>
                <IconImageInPicture></IconImageInPicture>
                <Group>
                    <Text>صورة</Text>
                    <Text>{amount}</Text>
                </Group>
            </Flex>
        </Flex>
    );
}

export default ImagesStats;