import { FunctionComponent } from "react";
import { useAuth } from "../core/auth/AuthenticationPrivuder";
import { NavLink } from "react-router-dom";
import { Avatar, Button, Container, Drawer, Flex, Group, Space, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowLeft, IconChevronLeft } from "@tabler/icons-react";
import ComingSoon from "./ComingSoon";
import localizeRole from "../core/users_management/localize_role";

interface UserTileProps {

}

const UserTile: FunctionComponent<UserTileProps> = () => {
    const auth = useAuth();
    const [opened, { open, close }] = useDisclosure();
    const r = auth.user.role;
    const theme = useMantineTheme();

    return (
        <div >
            <Drawer opened={opened} onClose={close} >
                <Flex direction={'column'} justify={'stretch'} dir="rtl">
                    <Flex direction={'row'} justify={'space-between'}>
                        <Flex direction={'column'} justify={'start'} align={'start'}>
                            <Text size="xl">الملف الشخصي</Text>
                            <Space h={6} />
                            <Text style={{ color: theme.colors.gray[6] }} size='xs'>تجد هنا جميع المعلومات الشخصية لهذا الحساب</Text>
                        </Flex>

                        <Avatar size={56} />
                    </Flex>

                    <Space h={'xl'} />

                    <Group grow>
                        <TextInput label='الأسم' readOnly value={auth.user.name} />
                        <TextInput label='المسمى الوظيفي للحساب' readOnly value={localizeRole(auth.user.role)} />
                    </Group>

                    <Space h={'lg'} />

                    <Flex>
                        <Button variant="subtle" onClick={close}>أغلاق </Button></Flex>
                </Flex>
            </Drawer>

            <div onClick={open} style={{ margin: 4, paddingBottom: 8 }}>
                <Flex justify={'space-between'} align={'center'}>
                    <Group>
                        <Avatar></Avatar>
                        <Flex direction={'column'}>
                            <Text>{auth.user.name}</Text>
                            <Text size="xs" style={{ color: theme.colors.gray[6] }}>{
                                localizeRole(r)
                            }</Text>
                        </Flex>
                    </Group>

                    <IconChevronLeft style={{ margin: 2, color: theme.colors.gray[5] }} />
                </Flex>
            </div>
        </div>
    );
}

export default UserTile;