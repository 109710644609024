import { FunctionComponent, useState } from "react";
import { Flex, Text, Group, TextInput, Loader, Space, Button, NumberInput, Select, Checkbox } from "@mantine/core";
import CENTER_STYLE from "../../core/CenterStyle";
import CLIENT from "../../core/client/client";
import { notifications } from "@mantine/notifications";
import { UserModelInfo } from "../../core/users_management/user_management_utils";

interface EmployeeDataFormProps {
    initial?: UserModelInfo,
    onSubmit: (c: UserModelInfo) => void
    close: (reload?: boolean) => void

}

const EmployeeDataForm: FunctionComponent<EmployeeDataFormProps> = ({ initial, onSubmit, close }) => {

    const [employee, setEmployee] = useState<UserModelInfo>(initial ?? {} as UserModelInfo);
    const [loading, setLoading] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);

    console.log(employee);

    return (
        <form onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            initial == undefined ? await saveNewEmployee(employee) : await updateEmployee(employee);
            setLoading(false);
            close(true)


        }}>
            {loading ? <div style={CENTER_STYLE}>
                <Loader />
            </div> : <Flex gap={'md'} direction={'column'} justify={'start'} dir='rtl' >

                <Text size="lg" fw={'bold'}>
                    أضافة موظف
                </Text>
                <Text size='xs'>
                    الرجاء ادخال جميع معلومات الموظف لغرض اضافته
                </Text>

                <Space h={'md'} />

                <TextInput
                    required
                    label='الاسم' placeholder="الاسم"
                    value={employee.name}
                    onChange={(e) => setEmployee({
                        ...employee,
                        name: e.currentTarget.value
                    })}
                />

                <Group grow>
                    <NumberInput
                        readOnly={initial != undefined}
                        hideControls
                        style={
                            {
                                direction: 'ltr'
                            }
                        }

                        leftSection={
                            <div style={{ paddingLeft: 20 }}>
                                <Text size="xs">+964</Text>
                            </div>
                        }
                        required
                        placeholder="رقم الهاتف"
                        label='ph'
                        value={employee.phone_number?.startsWith('+964') == true ? employee.phone_number.replace('+964', '') : employee.phone_number ?? undefined}
                        onChange={(e: any) => setEmployee({
                            ...employee,
                            phone_number: e.toString()
                        })}
                    />
                    <TextInput
                        readOnly={initial != undefined}

                        required
                        minLength={8}
                        label='كلمة السر'
                        value={employee?.password_sign_in}
                        onChange={(e: any) => setEmployee({
                            ...employee,
                            password_sign_in: e.currentTarget.value
                        })}
                    />

                </Group>

                <TextInput
                    required
                    label='العنوان'
                    placeholder="العنوان"
                    value={employee?.address}
                    onChange={(e: any) => setEmployee({
                        ...employee,
                        address: e.currentTarget.value
                    })}
                />
                <Select

                    required={true}
                    label='الرتبة'
                    placeholder='رتبة الموظف (محاسب, مدير..)'
                    data={[
                        { value: 'manager', label: 'مدير' },
                        { value: 'accountant', label: 'محاسب' },
                        { value: 'importer', label: 'خبير مالي' },
                        { value: 'arrival_manager', label: 'مسؤول وصول' },

                    ]}
                    value={employee?.role}
                    onChange={(value, _) => setEmployee({
                        ...employee,
                        role: value ?? undefined
                    })}
                />



                <Space h={0.5} />

                {isDeleting && <Flex justify={'space-between'} align={'center'}>
                    <Text>اؤكد على حذف الحساب بشكل نهائي و تجميده</Text>

                    <Checkbox checked={deleteConfirmed} onChange={(e) => setDeleteConfirmed(e.target.checked)}></Checkbox>
                </Flex>}

                <Flex direction={'row'} justify={'space-between'}>
                    <Button onClick={() => close()} variant={'subtle'}>اغلاق و عدم الحفظ</Button>
                    <Flex>
                        {initial != undefined && <Button color="red" mx={8} onClick={async () => {
                            if (!isDeleting) {
                                setIsDeleting(true);
                            } else if (deleteConfirmed && isDeleting) {
                                setLoading(true);
                                const res = await deleteUserCompletely(initial);

                                if (res == null) {
                                    setLoading(false);
                                    return;
                                }

                                close(true);
                            }

                        }} >{
                                deleteConfirmed && isDeleting ? "حذف نهائياً" : "حذف و تعطيل الحساب"
                            }</Button>}

                        <Button type="submit">{
                            initial == undefined ? "اضافة موظف جديد" : "حفظ التغييرات"
                        }</Button>

                    </Flex>

                </Flex>


            </Flex>}
        </form >


    );
}

async function saveNewEmployee(employee: UserModelInfo) {
    try {
        const c = CLIENT();

        console.warn(employee);

        const res = await c.post('/users', {
            ...employee,
            password: employee!.password_sign_in!,
            role: employee.role,
            address: employee?.address,
            phone_number: employee.phone_number?.startsWith('+') ? employee.phone_number : '+964' + employee.phone_number
        });


        notifications.show({
            title: "تمت العملية بنجاح",
            message: 'تمت العملية',
        });


    } catch (e) {
        console.error((e as any).response.data);
        alert((e as any)?.response?.data.message ?? "حدث خطا")
    }
}

async function updateEmployee(employee: UserModelInfo) {
    try {
        const c = CLIENT();

        console.warn(employee);

        const res = await c.patch(`/users/${employee.id!}`, {
            ...employee,
            address: employee?.address,
            role: employee.role,
            phone_number: undefined,
        });


        console.log(res.data);
        console.log(res.status);

        notifications.show({
            title: "تمت العملية بنجاح",
            message: 'تمت العملية',
        });


    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")
    }
}

async function deleteUserCompletely(employee: UserModelInfo) {
    try {
        const c = CLIENT();

        console.warn(employee);

        const res = await c.delete(`/users/${employee.id!}`);


        console.log(res.data);
        console.log(res.status);

        notifications.show({
            title: "تمت العملية بنجاح",
            message: 'تمت العملية',
        });


    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")
    }
}


export default EmployeeDataForm;