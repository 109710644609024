import { AppShell, Burger, Button, Center, CheckIcon, Drawer, Flex, Loader, NavLink, Progress, RingProgress, Slider, Space, useMantineColorScheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Icon, IconBinoculars, IconChartDots2, IconDatabase, IconHome, IconUser } from "@tabler/icons-react";
import { useAuth } from "../core/auth/AuthenticationPrivuder";
import CENTER_STYLE from "../core/CenterStyle";
import LogoHorizontal from "../components/LogoHorizontal";
import UserTile from "../components/UserTile";


const marks = [
  { value: 20, label: '20%' },
  { value: 50, label: '50%' },
  { value: 80, label: '80%' },
];


function Demo() {
  return <Slider defaultValue={40} marks={marks} />;
}

interface Destination {
  destination: string,
  label: string,
  allowedRoles: string[] | 'all',
  icon: any
}

export function MainLayout({ selectedRoute = "/", loading, children }: { selectedRoute?: string, loading?: boolean, children?: any }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [openedBurger, { toggle }] = useDisclosure();

  const isMobile = window.screen.width < 600;

  const auth = useAuth();
  console.log(auth?.user);
  const role: string | undefined = auth?.user?.role;
  const manager = ['manager'];


  return (
    <AppShell
      dir="rtl"
      header={{ height: isMobile ? 60 : 0 }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !openedBurger },
      }}
      padding="md"
    >
      {isMobile && <AppShell.Header>
        <Burger
          opened={opened}
          onClick={toggle}
          hiddenFrom="sm"
          size="sm"
        />



      </AppShell.Header>}

      <AppShell.Navbar p="md" >
        <Flex direction={'column'} justify={'space-between'} h={'100%'}>
          <div>
            <Space h="md" />

            {/* LOgO And name */}
            <LogoHorizontal width={164} />
            <h1
              className="pt-5 pb-2"
            >
              لوحة التحكم
            </h1>

            {/* DESTINATIONS */}
            {([
              {
                destination: "/",
                label: "أدارة السيارات",
                allowedRoles: 'all',
                icon: <IconHome />
              },
              {
                destination: "/statistics",
                label: "الآحصائيات",
                allowedRoles: manager,
                icon: <IconChartDots2 />

              },
              {
                destination: "/settings",
                label: "الأعدادات",
                allowedRoles: 'all',
                icon: <IconDatabase />

              },
              {
                destination: "/employees",
                label: "ادارة الموظفين",
                allowedRoles: manager,
                icon: <IconBinoculars />

              },
              {
                destination: "/customers",
                label: "أدارة العملاء",
                allowedRoles: manager,
                icon: <IconUser />
              }
            ] as Destination[])
              .map((value, index) =>

                value.allowedRoles == 'all' || value.allowedRoles.includes(role ?? "") ? <NavLink
                  mb={14}

                  href={value.destination}
                  label={value.label}

                  active={selectedRoute == value.destination}
                  leftSection={value.icon as any}
                /> : <div></div>

              )}
          </div>



          <UserTile />

        </Flex>
      </AppShell.Navbar>

      <AppShell.Main>
        {loading == true ?
          <div style={CENTER_STYLE}>
            <Loader></Loader>
          </div >
          : children}

      </AppShell.Main>


    </AppShell>
  );

  function ThemeManage() {
    const { setColorScheme, clearColorScheme, colorScheme } = useMantineColorScheme();

    return <>
      {
        colorScheme == 'auto' ? <Button onClick={() => setColorScheme('light')}>AUTO THEME, Set light</Button> :
          colorScheme == 'dark' ? <Button onClick={() => setColorScheme('light')}>Reset</Button> : <Button onClick={() => setColorScheme('dark')}>Set Dark</Button>}

    </>

  }
}