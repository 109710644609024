import { useMantineColorScheme } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { FunctionComponent } from "react";

interface LogoVerticalProps {
    width?: number,
}

const LogoVertical: FunctionComponent<LogoVerticalProps> = ({ width }) => {
    const s = useMantineColorScheme();

    return (<img src={s.colorScheme == "dark" ? "/logo_vertical_dark.png" : "/logo_vertical.png"} alt="" width={width || 178} />);
}

export default LogoVertical;