import { Button, FileInput, Flex, Loader, Space } from "@mantine/core";
import { FunctionComponent, useEffect, useState } from "react";
import CLIENT from "../../core/client/client";
import PaginatedResponse from "../../core/paginated_response";
import checkUnAuthenticated from "../../core/check_un_authenticated";
import CENTER_STYLE from "../../core/CenterStyle";
import DownloadIcon from "../../core/DownloadIcon";
import { notifications } from "@mantine/notifications";

interface FilesManagerForCustomerTypeProps {
    type_name: string,
    close: () => void,

}


interface UpdatableFiles {
    al_aqaba_file?: File;
    mount_ali_file?: File;
    om_file?: File;

}

const FilesManagerForCustomerType: FunctionComponent<FilesManagerForCustomerTypeProps> = ({ type_name, close }) => {


    const [loading, setLoading] = useState(true);



    const [data, setData] = useState<any>();
    const [filesSelected, setSelectedFiles] = useState<UpdatableFiles>({});

    function onload() {
        setLoading(true);
        loadAllFiles(type_name).then(e => {

            if (e == null) {
                return notifications.show({
                    color: "red",
                    title: "حدث خطأ",
                    message: "حدث اخطاء اثناء التحميل - اعد تحميل الصفحة ثم اعد المحاولة فيما بعد"
                });
            }

            setData(e);
            setLoading(false)
        });
    }

    useEffect(onload, []);

    return <form onSubmit={async (e) => {
        e.preventDefault();
        setLoading(true);
        const failed = await updateCustomerTypePrice(data.id, filesSelected);

        setLoading(false);



    }}>{loading ?

        <div style={CENTER_STYLE}>
            < Loader />
        </div >

        :
        <Flex direction={'column'}>

            <FileInput
                flex={5}
                clearable
                label='أم قـصــــر'

                placeholder={filesSelected?.om_file?.name ?? (data?.om == null || data?.om == undefined ? undefined : data?.om?.title)}
                rightSection={data?.om?.url == undefined ? undefined : <DownloadIcon url={data?.om?.url} />}
                onChange={(e) => setSelectedFiles({ ...filesSelected, om_file: e ?? undefined })}
                accept="application/pdf,application/vnd.ms-excel"
            />
            <Space h={'md'} />

            <FileInput
                flex={5}
                clearable
                label='جــبـــل الــعــلي'

                placeholder={filesSelected?.mount_ali_file?.name ?? (data?.mount_ali == null || data?.mount_ali == undefined ? undefined : data?.mount_ali?.title)}
                rightSection={data?.mount_ali?.url == undefined ? undefined : <DownloadIcon url={data?.mount_ali?.url} />}
                onChange={(e) => setSelectedFiles({ ...filesSelected, mount_ali_file: e ?? undefined })}
                accept="application/pdf,application/vnd.ms-excel"
            />


            <Space h={'md'} />
            <FileInput
                flex={5}
                clearable
                label='العــقــــــبــة'

                placeholder={filesSelected?.al_aqaba_file?.name ?? (data?.al_aqaba == null || data?.al_aqaba == undefined ? undefined : data?.al_aqaba?.title)}
                rightSection={data?.al_aqaba?.url == undefined ? undefined : <DownloadIcon url={data?.al_aqaba?.url} />}
                onChange={(e) => setSelectedFiles({ ...filesSelected, al_aqaba_file: e ?? undefined })}

                accept="application/pdf,application/vnd.ms-excel"
            />
            <Space h={'md'} />


            <Space h={'md'} />

            <Flex direction={'row'} justify={'space-between'}>
                <Button onClick={() => close()} variant={'subtle'}>اغلاق</Button>
                <Button type="submit">{
                    "حفظ التغييرات"
                }</Button>

            </Flex>
        </Flex>
        }</form>;
}


async function loadAllFiles(type_name: string, auth?: any): Promise<any | null> {
    try {
        const c = CLIENT();

        const res = await c.get(`/prices?type_name=${type_name}`)

        console.log(res.data.data[0]);

        return res.data.data[0];
    } catch (e) {
        return null;
    }

}



async function updateCustomerTypePrice(id: any, toUpdate: UpdatableFiles): Promise<any | null> {
    try {
        const c = CLIENT();

        const res = await c.postForm(`/prices/${id}`, toUpdate);

        console.log(res.data.data);

        return;
    } catch (e) {

        return null;
    }

}

export default FilesManagerForCustomerType;