import { Container, Flex, useMantineTheme, Text, Space, Group } from "@mantine/core";
import { IconUser } from "@tabler/icons-react";
import { FunctionComponent } from "react";

interface StatisticCardProps {
    title: string,
    subtitle: string,
    icon: any,
}

const StatisticCard: FunctionComponent<StatisticCardProps> = ({ title, subtitle, icon }) => {
    const theme = useMantineTheme();

    return (
        <Group grow>
            <Container style={{ backgroundColor: theme.colors.blue[0], color: theme.colors.blue[7], borderRadius: 12 }} >
                <Flex direction={'column'} justify={'start'} p={14}>
                    {icon}
                    <Space h={'sm'}></Space>
                    <Text size="xl" fw={'bold'}>{title}</Text>
                    <Space h={'xs'}></Space>
                    <Text>{subtitle}</Text>

                </Flex>

            </Container>
        </Group>

    );
}

export default StatisticCard;