import { Flex, Group, SegmentedControl, Space, Tabs, Text } from "@mantine/core";
import { FunctionComponent, useState } from "react";
import { useAuth } from "../../../core/auth/AuthenticationPrivuder";
import CarGeneralInfoForm from "./CarGeneralInfoForm";
import { CarGeneralInfo } from "./interface";
import CarShipmentPayForm from "./CarShipmentPayForm";
import CarPaymentForm from "./CarPaymentForm";
import CarArrivalForm from "./CarArrivalForm";

interface CarDataManagementViewProps {
    initialGeneral?: CarGeneralInfo,
    close: (reload?: boolean) => void
    setShouldReload: (r: boolean) => void;
}

const CarDataManagementView: FunctionComponent<CarDataManagementViewProps> = ({ initialGeneral, close, setShouldReload }) => {
    const auth = useAuth();
    const role = auth?.user?.role;
    const initialGeneralInfo = 'المعلومات العامة';
    const [value, setValue] = useState(initialGeneralInfo);

    const canViewCarPayment = role == 'manager' || role == 'accountant';
    const canViewCarShipmentPay = role == 'manager' || role == 'importer';
    const canViewCarArrivalInfo = role == 'manager' || role == 'arrival_manager';


    return <Flex direction={'column'} dir="rtl">
        <Space h='sm' />


        <Text size="xl" fw={'bold'}>معلومات السيارة</Text>
        <Space h={6} />
        <Text size='xs'>تجد هنا جميع المعلومات عن السيارة</Text>
        <Space h='md' />



        <Group>
            <SegmentedControl
                readOnly={initialGeneral == null}
                value={value}
                onChange={setValue}
                data={[
                    //
                    initialGeneralInfo,
                    ...canViewCarPayment ? ['تسديد السيارة'] : [],
                    ...canViewCarShipmentPay ? ['تسديد الشحن'] : [],
                    ...canViewCarArrivalInfo ? ['الوصـــول'] : [],

                ]}
            ></SegmentedControl>
        </Group>

        <Tabs value={value} onChange={() => { }}>


            <Tabs.Panel value={initialGeneralInfo}>
                <CarGeneralInfoForm initial={initialGeneral} close={(r) => close(r)} setShouldReload={setShouldReload} readOnly={role == 'accountant' || role == 'arrival_manager'} />
            </Tabs.Panel>
            <Tabs.Panel value='تسديد السيارة'>
                {canViewCarPayment && <CarPaymentForm initial={initialGeneral} close={(r) => close(r)} setShouldReload={setShouldReload} />}
            </Tabs.Panel>
            <Tabs.Panel value='تسديد الشحن'>
                {canViewCarShipmentPay && <CarShipmentPayForm initial={initialGeneral} close={(r) => close(r)} setShouldReload={setShouldReload} />}
            </Tabs.Panel>
            <Tabs.Panel value='الوصـــول'>
                {canViewCarArrivalInfo && <CarArrivalForm initial={initialGeneral} close={(r) => close(r)} setShouldReload={setShouldReload} />}

            </Tabs.Panel>
        </Tabs>


        <Space h='lg' />
    </Flex>;
}

export default CarDataManagementView;