import { useMantineColorScheme } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { FunctionComponent } from "react";

interface LogoHorizontalProps {
    width?: number,
}

const LogoHorizontal: FunctionComponent<LogoHorizontalProps> = ({ width }) => {
    const s = useMantineColorScheme();

    return (<img src={s.colorScheme == "dark" ? "/logo_horizontal_dark.png" : "/logo_horizontal.png"} alt="" width={width || 178} />);
}

export default LogoHorizontal;