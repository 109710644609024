import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthenticationPrivuder";

const PrivateRoute = () => {
    const user = useAuth();
    console.log(user?.token);
    if (user?.token == null || user?.token == undefined || user?.token?.length == 0) return <Navigate to="/auth" />;
    return <Outlet />;
};

export default PrivateRoute;
