import { FunctionComponent, useEffect, useState } from "react";
import CLIENT from "../core/client/client";
import { ComboboxItem, ComboboxStringData, ComboboxStringItem, MenuDropdown, Select } from "@mantine/core";

interface Customer {
    id: number,
    name: string,
}

interface CustomerFieldProps {
    initial?: Customer,
    readOnly?: boolean,
    required?: boolean,
    onSelected: (c?: Customer) => void,
}

const CustomerField: FunctionComponent<CustomerFieldProps> = ({ initial, onSelected, readOnly = false, required = false }) => {
    const [customers, setCustomers] = useState<Array<Customer> | undefined>(initial == undefined ? undefined : [initial]);

    useEffect(() => {
        if (!readOnly) { search().then(e => setCustomers(e)); }
    }, [])

    return <Select
        required={required}
        readOnly={readOnly}
        label="أسم العميل"
        placeholder="اختر اسم احد العملاء"
        dir="rtl"
        value={initial?.id?.toString()}
        onChange={(_value, option) => onSelected({
            name: option.label,
            id: Number.parseInt(_value!),
        })}
        data={customers?.map(e => {
            return { label: e.name, value: e.id.toString() } as any;
        })}
        searchable
    />;
}

async function search(name?: string): Promise<Array<Customer>> {
    const c = CLIENT();

    const res = await c.get('/customers', {
        params: name && {
            name: name,
        }
    });

    return res.data.data ?? [];
}

export default CustomerField;