import { FunctionComponent, useEffect, useState } from "react";
import { MainLayout } from "../../layout/MainLayout";
import { Button, Center, Drawer, Flex, Pagination, Space, Table } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import SectionHead from "../../components/SectionHead";
import { useAuth } from "../../core/auth/AuthenticationPrivuder";
import CarFilter, { CarFilterForm } from "../cars/CarFilterationForm";
import CarDataManagementView from "../cars/car_data/CarDataManagementView";
import { CarGeneralInfo } from "../cars/car_data/interface";
import PaginatedResponse from "../../core/paginated_response";
import CLIENT from "../../core/client/client";
import CustomerDataForm from "./CustomerDataForm";
import { formatDate } from "../../core/format_date";
import checkUnAuthenticated from "../../core/check_un_authenticated";
import { UserModelInfo } from "../../core/users_management/user_management_utils";

interface ClientsPageProps {

}

export interface CustomerStats {
    cars_count?: number;
    last_purchase: string | { created_at: string } | null; // Assuming last_purchase can be a string or null
}



export interface CustomerGeneralInfo extends UserModelInfo {
    stats?: CustomerStats,

}



const ClientsPage: FunctionComponent<ClientsPageProps> = () => {
    const [addCustomer, { open, close }] = useDisclosure(false);
    const auth = useAuth();

    const [loading, setLoading] = useState(true);

    const [currentCustomers, setCustomers] = useState<PaginatedResponse<CustomerGeneralInfo> | null>(null);

    /// used when each addCustomer is used to determine what to load on the dialog drawer
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerGeneralInfo | null>(null);

    function onLoad(withPage?: number) {
        setLoading(true)

        loadAllClients(withPage, auth).then(e => {
            setCustomers(e)
            console.log(e);
            setLoading(false)
        });
    }

    function selectAndOpenCustomer(c: CustomerGeneralInfo) {
        setSelectedCustomer(c);
        open();
    }

    useEffect(onLoad, []);

    return (

        <MainLayout loading={loading} selectedRoute="/customers">
            <Drawer
                size={'lg'}
                opened={addCustomer} onClose={() => {
                    setSelectedCustomer(null);
                    close();
                }}
            >
                <CustomerDataForm initial={selectedCustomer ?? undefined} close={(doesReload) => {
                    close();
                    setSelectedCustomer(null);

                    if (doesReload) onLoad();

                }} onSubmit={() => { }} />
            </Drawer>


            {/* HEAD */}
            <SectionHead title={'أدارة العملاء'}>
                <Button variant='outline' onClick={
                    () => onLoad()
                }>
                    أعادة التحميل
                </Button>
                <Button onClick={
                    () => {
                        open()
                    }
                }>
                    اضافة عميل
                </Button>
            </SectionHead>

            <Space h="sm" />


            {/* TABLE */}
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>الاسم</Table.Th>
                        <Table.Th>رقم الهاتف</Table.Th>
                        <Table.Th>كلمة السر</Table.Th>
                        <Table.Th>تاريخ الأضافة</Table.Th>
                        <Table.Th>العنوان</Table.Th>
                        <Table.Th>اخر شراء</Table.Th>
                        <Table.Th>نوع العميل</Table.Th>
                        <Table.Th>السيارات</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{
                    currentCustomers?.data?.map((element) => (
                        <Table.Tr key={element.name} onClick={() => selectAndOpenCustomer(element)} >
                            <Table.Td maw={224}>{element.name}</Table.Td>

                            <Table.Td maw={172} dir="ltr" align="right">{element.phone_number}</Table.Td>
                            <Table.Td>{'########'}</Table.Td>
                            <Table.Td>{formatDate(element?.created_at ?? "")}</Table.Td>
                            <Table.Td>{element.info?.address}</Table.Td>
                            <Table.Td>{element?.stats?.last_purchase != undefined ? formatDate((element!.stats!.last_purchase as any).created_at) : "غير متوفر"}</Table.Td>
                            <Table.Td>{element?.info?.customer_type}</Table.Td>
                            <Table.Td>{element.stats?.cars_count ?? 0}</Table.Td>
                        </Table.Tr>
                    ))}</Table.Tbody>
            </Table>

            <Space h={'lg'}></Space>

            <Center>
                <Pagination

                    total={currentCustomers?.last_page ?? 1} siblings={1} defaultValue={currentCustomers?.current_page} onChange={
                        (e) => onLoad(e)
                    } />
            </Center>

            <Space h={'lg'}></Space>


        </MainLayout >);
}


async function loadAllClients(withPage?: number, auth?: any): Promise<PaginatedResponse | null> {
    try {
        const c = CLIENT();

        const res = await c.get("/users", {
            params: {
                type: 'customer'
            }
        })

        console.log(res.data.data);

        return {
            data: res.data.data,
            ...res.data,
        };
    } catch (e) {
        checkUnAuthenticated(e, auth);
        return null;
    }

}

export default ClientsPage;