import { IconDownload } from "@tabler/icons-react";
import { FunctionComponent } from "react";

interface DownloadIconProps {
    url?: string,

}

const DownloadIcon: FunctionComponent<DownloadIconProps> = ({ url }) => {
    return (<a href={url} download target="_blank" rel="noopener noreferrer">
        <IconDownload />
    </a>);
}

export default DownloadIcon;