import { Container, Text, Group, Space, useMantineTheme, Flex } from "@mantine/core";
import { FunctionComponent } from "react";


interface FilterLabel {
    title: string,
    data: any
}

interface FiltersRowProps {
    content: FilterLabel[],
}

const FiltersRow: FunctionComponent<FiltersRowProps> = ({ content }) => {

    const colors = useMantineTheme().colors;

    return (
        <Group gap={'md'} mb='sm' justify="start" >
            {
                content.map(e =>
                    <div>
                        <Container p={12} style={{ backgroundColor: colors.blue[0], color: colors.blue[8], borderRadius: 7, paddingRight: 16, paddingLeft: 16, width: 'min' }}>
                            <Flex direction={'row'}>
                                <Text size="sm" fw={'bold'}>{e.title}:</Text>
                                <Space w={4} />
                                <Text size="sm">{e.data}</Text>
                            </Flex>
                        </Container>
                        <Space w={8} />
                    </div>)
            }
        </Group>
    );
}

export default FiltersRow;