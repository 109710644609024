import React, { useEffect, useState } from "react";
import { MainLayout } from "../../layout/MainLayout";
import SectionHead from "../../components/SectionHead";
import { Button, Drawer, Flex, Grid, SimpleGrid, useMantineTheme } from "@mantine/core";
import CarFilter, { CarFilterForm } from "../cars/CarFilterationForm";
import { useDisclosure } from "@mantine/hooks";
import StatsFilterForm, { StatisticsFilter } from "./StatsFilterForm";
import CLIENT from "../../core/client/client";
import { AxiosError } from "axios";
import checkUnAuthenticated from "../../core/check_un_authenticated";
import { useAuth } from "../../core/auth/AuthenticationPrivuder";
import StatisticCard from "./StatisticCard";
import { IconBusStop, IconCar, IconDiscount, IconHandStop, IconRosetteDiscountFilled, IconShip, IconStackPop, IconUser, IconUserFilled } from "@tabler/icons-react";
import FiltersRow from "../../components/ FiltersRow";
import { title } from "process";
import { formatDate } from "../../core/format_date";



interface StatisticsModel {
    total_cars?: number;
    sold?: number;
    total_clients?: number;
    mount_ali?: number;
    al_aqaba?: number;
    om_q?: number;
    stopped_clients?: number;
}



function StatisticsPage() {

    const [loading, setLoading] = useState(true);
    const [filter, setFilters] = useState<StatisticsFilter>();
    const [stats, setStats] = useState<StatisticsModel>();
    const [filtersOpen, { open, close }] = useDisclosure(false);
    const auth = useAuth();



    function onLoad(filter?: StatisticsFilter) {
        setLoading(true);

        loadStatistics(filter, auth).then(e => {
            if (e == null) {
                return;
            }
            setStats(e);
            console.log(e, filter);
            setLoading(false)
        });
    }



    useEffect(onLoad, []);

    const theme = useMantineTheme();

    return (<MainLayout selectedRoute="/statistics" loading={loading}>

        {/* HEAD */}
        <SectionHead title={'الأحصائيات'} filtering={filter != undefined}>
            <Button variant='outline' onClick={
                () => onLoad()
            }>
                أعادة التحميل
            </Button>
            <Button onClick={
                () => {
                    open()
                }
            }>
                فلترة الأحصائيات
            </Button>
        </SectionHead>

        <Flex direction={'row'} justify={'start'}>
            <FiltersRow content={[
                ...filter?.from == undefined ? [] : [{ title: 'من التاريخ:', data: formatDate(filter.from) }],
                ...filter?.to == undefined ? [] : [{ title: 'حتى التاريخ:', data: formatDate(filter.to) }],
            ]} />
        </Flex>

        <Drawer opened={filtersOpen} onClose={close} title="فلترة الاحصائيات"  >
            <StatsFilterForm
                close={close}
                onSubmit={(s) => {
                    console.log(s);
                    close();
                    setFilters(s);
                    // will reload by state assigned filter AND reset pagination
                    console.log(s);
                    onLoad(s);
                }
                } />
        </Drawer>

        <SimpleGrid cols={{ base: 1, sm: 2, md: 3, lg: 3 }}
        >

            <StatisticCard title="أجمالي عدد السيارات" subtitle={
                (stats?.total_cars ?? 0).toString() + " سيارة"
            } icon={<IconCar size={64}></IconCar>}></StatisticCard>

            <StatisticCard title="المبيعــــات" subtitle={
                (stats?.sold ?? 0).toString() + " سيارة"
            } icon={<IconRosetteDiscountFilled size={64}></IconRosetteDiscountFilled>}></StatisticCard>

            <StatisticCard title="عدد العملاء" subtitle={
                (stats?.total_clients ?? 0).toString() + " عميل"
            } icon={<IconUserFilled size={64}></IconUserFilled>}></StatisticCard>

            <StatisticCard title="مشتريات (ام قصر)" subtitle={
                (stats?.om_q ?? 0).toString() + " سيارة"
            } icon={<IconShip size={64}></IconShip>}></StatisticCard>

            <StatisticCard title="مشتريات (العقبة)" subtitle={
                (stats?.al_aqaba ?? 0)?.toString() + " سيارة"
            } icon={<IconShip size={64}></IconShip>}></StatisticCard>

            <StatisticCard title="مشتريات (جبل علي)" subtitle={(stats?.mount_ali ?? 0)?.toString() + " سيارة"
            } icon={<IconShip size={64}></IconShip>}></StatisticCard>

            <StatisticCard title="عملاء متوقفين" subtitle={(stats?.stopped_clients ?? 0)?.toString() + " عميل"
            } icon={<IconHandStop size={64}></IconHandStop>}></StatisticCard>

            {/* <StatisticCard title="" subtitle={ (stats?.total_cars?? 0)?.toString() 

            } icon={<IconUser size={64}></IconUser>}></StatisticCard> */}


        </SimpleGrid>


    </MainLayout>);
}


async function loadStatistics(f?: StatisticsFilter, auth?: any): Promise<StatisticsModel | null> {

    try {
        const client = CLIENT();


        const res = await client.get("/statistics", {
            params: {
                ...f,

            }
        });

        console.log(res.data.data);

        return {
            ...res.data.data,

        };
    } catch (error: AxiosError | any) {
        checkUnAuthenticated(error, auth);

        return null;

    }
}


export default StatisticsPage;