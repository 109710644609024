import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthenticationPrivuder";

const RoleBasedRoute = (attributes: { allowedRoles: string[] }) => {
    const user = useAuth().user;
    console.log(user?.role);

    if (!attributes.allowedRoles.includes(user?.role)) return <Navigate to="/" />;
    return <Outlet />;
};

export default RoleBasedRoute;
