import { FunctionComponent } from "react";
import { MainLayout } from "../../layout/MainLayout";
import SectionHead from "../../components/SectionHead";
import { Button, Container, Drawer, Flex, Group, SimpleGrid, Space, Switch, Text, useMantineColorScheme } from "@mantine/core";
import { useAuth } from "../../core/auth/AuthenticationPrivuder";
import { useDisclosure } from "@mantine/hooks";
import PricingFilesManager from "./PricingFilesManager";

interface SettingsPageProps {

}

const SettingsPage: FunctionComponent<SettingsPageProps> = () => {

    const { setColorScheme, clearColorScheme, colorScheme } = useMantineColorScheme({ keepTransitions: true });
    const [openedPricesManager, { open, close }] = useDisclosure();

    const auth = useAuth();
    const role = auth.user.role;


    return <MainLayout selectedRoute="/settings" >


        <Drawer opened={openedPricesManager} onClose={close}>
            <PricingFilesManager close={close} />
        </Drawer>

        <SectionHead title="الأعدادات" subtitle="يمكنك تغيير الاعدادات الخاصة بك هنا"></SectionHead>

        <Space h={'lg'}></Space>

        <SimpleGrid cols={2} >
            <Card
                t={"المظهر"}
                d="يمكنك تغيير الشكل و المظهر الى الوضع الليلي او النهاري حسب تفضيلك"
            >
                <Group gap={'sm'}>
                    <Text>الوضع الليلي</Text>

                    <Switch checked={colorScheme == 'dark'}
                        onChange={(event) => setColorScheme(colorScheme == 'light' ? 'dark' : 'light')}
                    />
                </Group>
            </Card>
            <Card
                t={"الجلسة"}
                d="يمكنك تسجيل الخروج و انهاء الجلسة عن طريق الزر ادناه"
            >
                <Group>
                    <Button color="red" fullWidth={false} onClick={
                        auth.logout
                    }

                    >تسجيل الخروج</Button>
                </Group>
            </Card>
            {role == 'manager' && <Card
                t={"قوائم الاسعار (المدير فقط)"}
                d="سيمكنك تغيير و تعديل و معاينة كافة قوائم الاسعار لكل وجهات التوصيل"
            >
                <Group>
                    <Button fullWidth={false} onClick={() => {
                        open();
                    }}>
                        تعديل و عرض القوائم
                    </Button>
                </Group>
            </Card>}
        </SimpleGrid>
    </MainLayout >;
}

function Card({ t, d, children }: { t: string, d: string, children: any }) {
    return <Flex direction={'column'} justify={'start'} >
        <Text size="xl">{t}</Text>
        <Space h={'xs'}></Space>

        <Text size="xs">{d}</Text>
        <Space h={'lg'}></Space>
        {children}
    </Flex>;
}

export default SettingsPage;