import { Flex, TextInput, Space, Button, Group, Text } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { FunctionComponent } from "react";
import CarFilter from "../cars/CarFilterationForm";

interface StatsFilterFormProps {
    onSubmit: (filter?: StatisticsFilter) => void;
    close: any;
}

export interface StatisticsFilter {
    from?: Date,
    to?: Date,
}

const StatsFilterForm: FunctionComponent<StatsFilterFormProps> = ({ close, onSubmit }) => {
    let data: StatisticsFilter = {
    };


    return <Flex gap={'md'} direction={'column'} justify={'start'} dir='rtl' >

        <Text size="lg" fw={'bold'}>
            تصفية الاحصائيات        </Text>

        <Text size='xs'>
            فلترة و تصفية الاحصائيات حسب الموعد او التوقيق        </Text>




        <Flex direction={'row'} gap={'md'}>
            <DateInput label='من التاريخ' placeholder="التاريخ الاول" onChange={(e) => data.from = e ?? undefined} />
            <DateInput label='حتى التاريخ' placeholder="التاريخ الثاني" onChange={(e) => data.to = e ?? undefined} />
        </Flex>


        <Space h='xs'></Space>


        <Flex direction={'row'} gap={'md'} justify={'space-between'}>
            <Button onClick={close} variant='subtle'>اغلاق</Button>

            <Group>
                <Button onClick={() => onSubmit(undefined)} variant="outline">الغاء الفلاتر</Button>

                <Button onClick={() => onSubmit((data?.from === undefined && data?.to === undefined) ? undefined : data)}>عرض النتائج</Button>
            </Group>



        </Flex>
    </Flex>;
}

export default StatsFilterForm;