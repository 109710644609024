import { FunctionComponent, useState } from "react";
import ComingSoon from "../../components/ComingSoon";
import { Button, Flex, Group, SegmentedControl, Space, Tabs, Text } from "@mantine/core";
import CarArrivalForm from "../cars/car_data/CarArrivalForm";
import CarGeneralInfoForm from "../cars/car_data/CarGeneralInfoForm";
import CarPaymentForm from "../cars/car_data/CarPaymentForm";
import CarShipmentPayForm from "../cars/car_data/CarShipmentPayForm";
import FilesManagerForCustomerType from "./FilesManagerForCustomerType";

interface PricingFilesManagerProps {
    close: () => void,
}

const PricingFilesManager: FunctionComponent<PricingFilesManagerProps> = ({ close }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState('vip');

    return (<Flex direction={'column'} justify={'center'} dir="rtl">
        <Space h={'xs'} />
        <Text size="lg" fw={'bold'}>
            قوائم أسعــــار العمـــلاء الشهرية
        </Text>
        <Space h={'xs'} />
        <Text size='xs'>
            رؤية و تغيير ملفات جميع قوائم الاسعار
            <br />
            عند اضافة او استبدال اي ملف احرص على ان يكون بصيغة PDF حصرا و ان يكون اسمه مختلف عن بقية الملفات
        </Text>

        <Space h={'md'} />

        <Group>
            <SegmentedControl
                value={selectedTab}
                onChange={setSelectedTab}
                data={[
                    { value: 'vip', label: 'VIP' },
                    { value: '1', label: 'نوع 1' },
                    { value: '2', label: 'نوع 2' },
                    { value: '3', label: 'نوع 3' },

                ]}
            ></SegmentedControl>
        </Group>
        <Space h={'sm'} />

        <Tabs value={selectedTab} onChange={() => { }}>


            <Tabs.Panel value={'vip'}>
                <FilesManagerForCustomerType type_name="vip" close={close} />
            </Tabs.Panel>

            <Tabs.Panel value='1'>
                <FilesManagerForCustomerType type_name="1" close={close} />
            </Tabs.Panel>

            <Tabs.Panel value='2'>
                <FilesManagerForCustomerType type_name="2" close={close} />
            </Tabs.Panel>

            <Tabs.Panel value='3'>
                <FilesManagerForCustomerType type_name="3" close={close} />
            </Tabs.Panel>
        </Tabs>

        <Space h={'md'} />





    </Flex>);
}

export default PricingFilesManager;