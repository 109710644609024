import { da } from "@faker-js/faker";
import { Flex, TextInput, Text, NumberInput, Button, Group, Space } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { FunctionComponent } from "react";
import CustomerField from "../../components/CustomerField";
import '@mantine/dates/styles.css';


interface CarFilter {
    car_name_and_model?: string,
    customer?: number,
    lot?: string,
    vin?: string,
    from?: Date,
    to?: Date,
}


interface CarFilterFormProps {
    onSubmit: (carFilter?: CarFilter) => void;
    close: any;
}

const CarFilterForm: FunctionComponent<CarFilterFormProps> = (props: CarFilterFormProps) => {
    let data: CarFilter = {
    };


    return <Flex gap={'md'} direction={'column'} justify={'start'} dir='rtl' >

        <Text size="lg" fw={'bold'}>
            البحث و التصفية
        </Text>
        <Text size='xs'>
            يمكنك عرض السيارات التي تريدها عن طريق البحث و التصفية في القوائم, يرجى الانتباه ان جميع الخيارات ادناه أختيارية (حيث يمكن كتابة الاسم مثلا لوحده او “من تاريخ” و “الى تاريخ”
        </Text>


        <TextInput
            dir="rtl" label="اسم السيارة و الموديل"
            placeholder="اسم او موديل السيارة "
            onChange={e => data.car_name_and_model = e.currentTarget.value ?? undefined}
        />


        <CustomerField
            onSelected={e => {
                data.customer = e?.id;
                console.log(e);
            }}
        />

        <Flex direction={'row'} gap={'md'}>
            <DateInput label='من التاريخ' placeholder="التاريخ الاول" onDateChange={(e) => data.from = e} />
            <DateInput label='حتى التاريخ' placeholder="التاريخ الثاني" onDateChange={(e) => data.to = e} />
        </Flex>
        <Flex direction={'row'} gap={'md'}>
            <TextInput label='اللوت' placeholder="رقم اللوت" onChange={(e) => data.lot = e?.toString() ?? undefined} />
            <TextInput
                dir="rtl" label="VIN رقم الشاصي"
                placeholder="ال vin"
                onChange={e => data.vin = e.currentTarget.value ?? undefined}
            />
        </Flex>

        <Space h='xs'></Space>


        <Flex direction={'row'} gap={'md'} justify={'space-between'}>
            <Button onClick={props.close} variant='subtle'>اغلاق</Button>

            <Group>
                <Button onClick={() => props.onSubmit(undefined)} variant="outline">الغاء الفلاتر</Button>

                <Button onClick={() => props.onSubmit(data)}>عرض النتائج</Button>
            </Group>



        </Flex>
    </Flex>;
}




export default CarFilter;
export { CarFilterForm };