import { Drawer, Button, Space, Table, Center, Pagination } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { FunctionComponent, useEffect, useState } from "react";
import SectionHead from "../../components/SectionHead";
import { useAuth } from "../../core/auth/AuthenticationPrivuder";
import { formatDate } from "../../core/format_date";
import PaginatedResponse from "../../core/paginated_response";
import { MainLayout } from "../../layout/MainLayout";

import { UserModelInfo } from "../../core/users_management/user_management_utils";
import CLIENT from "../../core/client/client";
import checkUnAuthenticated from "../../core/check_un_authenticated";
import CustomerDataForm from "../clients/CustomerDataForm";
import localizeRole from "../../core/users_management/localize_role";
import EmployeeDataForm from "./EmployeeDataForm";

interface EmployeesPageProps {

}

const EmployeesPage: FunctionComponent<EmployeesPageProps> = () => {
    const [addEmployee, { open, close }] = useDisclosure(false);
    const auth = useAuth();

    const [loading, setLoading] = useState(true);

    const [currentEmployees, setEmployees] = useState<PaginatedResponse<UserModelInfo> | null>(null);

    /// used when each addEmployee is used to determine what to load on the dialog drawer
    const [selectedEmployee, setSelectedEmployee] = useState<UserModelInfo | null>(null);

    function onLoad(withPage?: number) {
        setLoading(true)

        loadAllEmployees(withPage, auth).then(e => {
            setEmployees(e)
            console.log(e);
            setLoading(false)
        });
    }

    function selectAndOpenEmployee(c: UserModelInfo) {
        setSelectedEmployee(c);
        open();
    }

    useEffect(onLoad, []);

    return (

        <MainLayout loading={loading} selectedRoute="/employees">
            <Drawer
                size={'lg'}
                opened={addEmployee} onClose={() => {
                    setSelectedEmployee(null);
                    close();
                }}
            >
                <EmployeeDataForm initial={selectedEmployee ?? undefined} close={(doesReload) => {
                    close();
                    setSelectedEmployee(null);

                    if (doesReload) onLoad();

                }} onSubmit={() => { }} />
            </Drawer>


            {/* HEAD */}
            <SectionHead title={'أدارة الموظفين'}>
                <Button variant='outline' onClick={
                    () => onLoad()
                }>
                    أعادة التحميل
                </Button>
                <Button onClick={
                    () => {
                        open()
                    }
                }>
                    اضافة موظف
                </Button>
            </SectionHead>

            <Space h="sm" />


            {/* TABLE */}
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>الاسم</Table.Th>
                        <Table.Th>رقم الهاتف</Table.Th>
                        <Table.Th>كلمة السر</Table.Th>
                        <Table.Th>تاريخ الأضافة</Table.Th>
                        <Table.Th>العنوان</Table.Th>
                        <Table.Th>الرتبة</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{
                    currentEmployees?.data?.map((element) => (
                        <Table.Tr key={element.name} onClick={() => selectAndOpenEmployee(element)} >
                            <Table.Td maw={224}>{element.name}</Table.Td>

                            <Table.Td maw={172} dir="ltr" align="right">{element.phone_number}</Table.Td>
                            <Table.Td>{'########'}</Table.Td>
                            <Table.Td>{formatDate(element?.created_at ?? "")}</Table.Td>
                            <Table.Td>{element?.address}</Table.Td>
                            <Table.Td>{localizeRole(element?.role)}</Table.Td>
                        </Table.Tr>
                    ))}</Table.Tbody>
            </Table>

            <Space h={'lg'}></Space>

            <Center>
                <Pagination

                    total={currentEmployees?.last_page ?? 1} siblings={1} defaultValue={currentEmployees?.current_page} onChange={
                        (e) => onLoad(e)
                    } />
            </Center>

            <Space h={'lg'}></Space>


        </MainLayout >);
}


async function loadAllEmployees(withPage?: number, auth?: any): Promise<PaginatedResponse | null> {
    try {
        const c = CLIENT();

        const res = await c.get("/users", {
            params: { type: 'employee' }
        })

        console.log(res.data.data);

        return {
            ...res.data,
            data: res.data.data.users,
        };
    } catch (e) {
        checkUnAuthenticated(e, auth);
        return null;
    }

}

export default EmployeesPage;