import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainLayout } from './layout/MainLayout';
import AllCarsListing from './features/cars/AllCarsListing';
import dayjs from "dayjs";
import { AuthForm } from './components/AuthForm';


import { dir } from 'console';
import AuthProvider from './core/auth/AuthenticationPrivuder';
import PrivateRoute from './core/auth/PrivateRoute';
import RoleBasedRoute from './core/auth/RoleBasedRoute';
import StatisticsPage from './features/statistics/StatisticsPage';
import SettingsPage from './features/settings/SettingsPage';
import ClientsPage from './features/clients/ClientsPage';
import { Notifications } from '@mantine/notifications';
import EmployeesPage from './features/employees/EmployeesPage';



export default function App() {

  dayjs.locale('ar');


  return (

    <MantineProvider defaultColorScheme="auto" withCssVariables={true} withGlobalClasses={true} theme={
      {
        fontFamily: 'Alexandria, sans-serif',

      }

    }

    >
      <Notifications />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/auth" element={<AuthForm />} />

            <Route element={<PrivateRoute />}>
              <Route path="/" element={<AllCarsListing />} />
              <Route path="/settings" element={<SettingsPage />} />

              <Route element={<RoleBasedRoute allowedRoles={[
                'manager'
              ]} />}>
                <Route path="/employees" element={<EmployeesPage />} />
                <Route path="/customers" element={<ClientsPage />} />
                <Route path="/statistics" element={<StatisticsPage />} />


              </Route>
            </Route>


          </Routes>
        </AuthProvider>
      </BrowserRouter>




    </MantineProvider >
  );
}


