import { AxiosError } from "axios";

function checkUnAuthenticated(error: any, auth: any) {

    if ((error as AxiosError | undefined)?.response?.status === 401) {
        auth?.logout();
    } else {
        alert((error as any)?.response?.data.message ?? "حدث خطا");

    }
}
export default checkUnAuthenticated;