import { FunctionComponent, useState } from "react";
import { CustomerGeneralInfo } from "./ClientsPage";
import { Flex, Text, Group, TextInput, Loader, Space, Button, NumberInput, Select } from "@mantine/core";
import CENTER_STYLE from "../../core/CenterStyle";
import CLIENT from "../../core/client/client";
import { notifications } from "@mantine/notifications";
import { formatDate } from "../../core/format_date";

interface CustomerDataFormProps {
    initial?: CustomerGeneralInfo,
    onSubmit: (c: CustomerGeneralInfo) => void
    close: (reload?: boolean) => void

}

const CustomerDataForm: FunctionComponent<CustomerDataFormProps> = ({ initial, onSubmit, close }) => {

    const [customer, setCustomer] = useState<CustomerGeneralInfo>(initial ?? {} as CustomerGeneralInfo);
    const [loading, setLoading] = useState(false);

    console.log(customer);

    return (
        <form onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            initial == undefined ? await saveNewCustomer(customer) : await updateCustomer(customer);
            setLoading(false);
            close(true)


        }}>
            {loading ? <div style={CENTER_STYLE}>
                <Loader />
            </div> : <Flex gap={'md'} direction={'column'} justify={'start'} dir='rtl' >

                <Text size="lg" fw={'bold'}>
                    أضافة عميل
                </Text>
                <Text size='xs'>
                    الرجاء ادخال جميع معلومات العميل لغرض اضافته
                </Text>

                <Space h={'md'} />

                <TextInput
                    required
                    label='الاسم' placeholder="الاسم"
                    value={customer.name}
                    onChange={(e) => setCustomer({
                        ...customer,
                        name: e.currentTarget.value
                    })}
                />

                <Group grow>
                    <NumberInput
                        readOnly={initial != undefined}
                        hideControls
                        style={
                            {
                                direction: 'ltr'
                            }
                        }

                        leftSection={
                            <div style={{ paddingLeft: 20 }}>
                                <Text size="xs">+964</Text>
                            </div>
                        }
                        required
                        placeholder="رقم الهاتف"
                        label='ph'
                        value={customer.phone_number?.startsWith('+964') == true ? customer.phone_number.replace('+964', '') : customer.phone_number ?? undefined}
                        onChange={(e: any) => setCustomer({
                            ...customer,
                            phone_number: e.toString()
                        })}
                    />
                    <TextInput
                        readOnly={initial != undefined}

                        required
                        minLength={8}
                        label='كلمة السر'
                        value={customer?.info?.login_password}
                        onChange={(e: any) => setCustomer({
                            ...customer,
                            info: {
                                ...customer?.info,
                                login_password: e.currentTarget.value
                            } as any
                        })}
                    />

                </Group>

                <TextInput
                    required
                    label='العنوان'
                    placeholder="العنوان"
                    value={customer?.info?.address}
                    onChange={(e: any) => setCustomer({
                        ...customer,
                        info: {
                            ...customer?.info,
                            address: e.currentTarget.value
                        } as any
                    })}
                />
                <Select

                    required={true}
                    label='نوع العميل'
                    placeholder="نوع العميل"
                    data={[
                        { value: '1', label: 'نوع 1' },
                        { value: '2', label: 'نوع 2' },
                        { value: '3', label: 'نوع 3' },
                        { value: 'vip', label: 'VIP' },

                    ]}
                    value={customer?.info?.customer_type}
                    onChange={(value, _) => setCustomer({
                        ...customer,
                        info: {
                            ...customer?.info,
                            customer_type: value
                        } as any
                    })}
                />
                {initial &&
                    <div>

                        <Space h={'lg'} />

                        <Text size="md" fw={'bold'} mb={'xs'}>
                            أحصائيات العميل
                        </Text>
                        <Text size='xs' mb={'md'}>
                            هنا تجد احصائيات خاصة بهذا العميل
                        </Text>


                        <Group gap={'md'} grow>
                            <TextInput
                                readOnly
                                label='آخر عملية شراء'
                                placeholder="آخر عملية شراء"
                                value={customer?.stats?.last_purchase == null ? undefined : formatDate((customer?.stats?.last_purchase as any).created_at ?? customer?.stats?.last_purchase)}
                            />
                            <TextInput
                                readOnly
                                label='عدد السيارات'
                                placeholder='عدد السيارات'
                                value={customer?.stats?.cars_count ?? 0}
                            />
                        </Group>
                    </div>
                }

                <Space h={0.5} />

                <Flex direction={'row'} justify={'space-between'}>
                    <Button onClick={() => close()} variant={'subtle'}>اغلاق و عدم الحفظ</Button>
                    <Button type="submit">{
                        initial == undefined ? "اضافة عميل جديد" : "حفظ التغييرات"
                    }</Button>

                </Flex>


            </Flex>}
        </form>


    );
}

async function saveNewCustomer(customer: CustomerGeneralInfo) {
    try {
        const c = CLIENT();

        console.warn(customer);

        const res = await c.post('/users', {
            ...customer,
            password: customer!.info!.login_password!,
            role: 'customer',
            address: customer.info?.address,
            customer_type: customer.info?.customer_type,
            phone_number: customer.phone_number?.startsWith('+') ? customer.phone_number : '+964' + customer.phone_number
        });


        notifications.show({
            title: "تمت العملية بنجاح",
            message: 'تمت العملية',
        });


    } catch (e) {
        console.error((e as any).response.data);
        alert((e as any)?.response?.data.message ?? "حدث خطا")
    }
}

async function updateCustomer(customer: CustomerGeneralInfo) {
    try {
        const c = CLIENT();

        console.warn(customer);

        const res = await c.patch(`/users/${customer.id!}`, {
            ...customer,
            address: customer.info?.address,
            customer_type: customer.info?.customer_type,
        });


        console.log(res.data);
        console.log(res.status);

        notifications.show({
            title: "تمت العملية بنجاح",
            message: 'تمت العملية',
        });


    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")
    }
}


export default CustomerDataForm;